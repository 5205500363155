.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent; 
  padding: 0.6rem;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; 
  transition: background-color 0.3s; 
}


.content {
  padding-top: 3.6rem;
}

.navbar ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  margin: 0;
  padding: 0 1em;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.navbar a:hover {
  color: #ffcc00;
}

.navbar li {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% - 0px);
  left: 0;
  background-color: #444;
  padding: 0;
  list-style: none;
  margin: 0;
  min-width: 200px;
  z-index: 200;
  display: none;
}

.dropdown-menu li {
  padding: 0.1em;
}

.dropdown-menu li a {
  display: block;
  padding: 0.3em;
  color: white;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  background-color: #555;
}

.navbar .dropdown:hover .dropdown-menu {
  display: block;
}

.navbar .dropdown:not(:hover) .dropdown-menu {
  display: none;
}

.navbar-address {
  color: white;
  text-align: right; /* Align address to the right */
  max-width: 50%; /* Limit width to prevent overflow */
  margin-right: 20px; /* Adjust margin to shift address to the left */
}

.navbar-address div {
  font-size: 1.2rem;
  margin-bottom: 5px; /* Adjust spacing between lines */
}