.feature-section {
    text-align: center;
    padding: 2rem;
    background-color: transparent;
    margin-top: -200px;
  }
  
  .feature-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: white; 
  }
  
  .feature-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
  }
  
  .feature-card {
    background-color: black; 
    border-radius: 10px;
    padding: 1rem;
    width: 20%;
    min-width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .feature-card:hover {
    transform: scale(1.05);
  }
  
  .feature-icon {
    font-size: 3rem;
    color: #ff4c4c;
    margin-bottom: 1rem;
  }
  
  .feature-card-title {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 1rem;
  }
  
  .feature-card-description {
    font-size: 1rem;
    color: white;
  }
  